@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat.ttf') format('TrueType');
}
@font-face {
  font-family: 'MontserratBold';
  src: url('./assets/fonts/MontserratBold.ttf') format('TrueType');
}
@font-face {
  font-family: 'MontserratItalic';
  src: url('./assets/fonts/MontserratItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'MontserratBoldItalic';
  src: url('./assets/fonts/MontserratBoldItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'MontserratLight';
  src: url('./assets/fonts/MontserratLight.ttf') format('TrueType');
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiInputBase-root,
.MuiFormLabel-root {
  font-family: 'Montserrat', sans-serif !important;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

* {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

.Toastify__toast {
  padding: 16px !important;
}

.Toastify__toast--default {
  border-radius: 20px;
}
